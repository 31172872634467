.home-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    width: 100%;
  }
  
  .card-container {
    flex: 1 0 auto; /* Replace flex-grow: 1; with this line */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: auto; /* Make the card-container scrollable */
  }
  
  .button-bar {
    width: 100vw;
    background: #f8f8f8;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
  }

  .button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    background-color: #a16427;
    /* Green */
    color: white;
    cursor: pointer;
}

.button:hover {
    background-color: #192759;
    /* Darker green */
}